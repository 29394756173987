import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResendEmailCountdownTimer from '@shared/ui/misc/ResendEmailCountdownTimer';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import { ContainerColumn } from '@components/styled';
import { BodyMedium, TextEmphasizedMediumBold } from '@components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
import { maskEmail } from '@shared/lib/email';
import { useAppSelector } from '@store/Hooks';
import { useLazyGetResetPasswordLinkQuery } from '@shared/api/auth';
const Container = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: '100%',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
    maxWidth: '420px',
}));
const Content = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.xl,
}));
const Title = TextEmphasizedMediumBold;
const ButtonContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.m,
    width: '100%',
    height: 'auto',
}));
const Confirmation = () => {
    const { t } = useTranslation();
    const { toast } = useToastNotification();
    const { emailForReset } = useAppSelector(state => state.auth);
    const [displayTimer, setDisplayTimer] = useState(false);
    const startTimer = () => {
        setDisplayTimer(true);
    };
    const hideTimer = () => {
        setDisplayTimer(false);
    };
    const [getPasswordLink, { isLoading: isResetting, isSuccess, isError, error, },] = useLazyGetResetPasswordLinkQuery();
    const sendResetPasswordLink = () => {
        if (emailForReset) {
            getPasswordLink({ email: emailForReset });
        }
        else {
            toast({
                message: 'Internal error: emailForReset was not set',
                variant: 'error',
            });
        }
    };
    if (isSuccess) {
        startTimer();
    }
    if (isError) {
        toast({
            message: JSON.stringify(error),
            variant: 'error',
        });
    }
    return (_jsxs(Container, { children: [_jsxs(Content, { children: [_jsx(Title, { children: t('auth.reset_password.steps.confirmation.title', { ns: 'common' }) }), _jsx(BodyMedium, { children: t('auth.reset_password.steps.confirmation.text', { email: maskEmail(emailForReset || ''), ns: 'common' }) })] }), _jsxs(ButtonContainer, { children: [displayTimer && (_jsx(ResendEmailCountdownTimer, { onTimeOver: hideTimer })), _jsx(Button, { variant: 'filled', color: 'primary', isLoading: isResetting, onClick: sendResetPasswordLink, disabled: isResetting || displayTimer, fullWidth: true, children: t('auth.reset_password.steps.confirmation.resend_link_text', { ns: 'common' }) })] })] }));
};
export default Confirmation;
